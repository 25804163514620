/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap functions
@import 'bootstrap/functions';
@import 'bootstrap/mixins';

// Argon functions
@import 'custom/functions';
@import 'custom/variables';
@import 'bootstrap/variables';

// Argon mixins
@import 'custom/mixins';

// Bootstrap components
@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms';
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/jumbotron';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/utilities';
@import 'bootstrap/print';

// Argon components
@import 'custom/components';
@import 'custom/utilities';
@import 'custom/vendors';

// React differences
@import 'react/react-differences';

.bg-secondary {
  background-color: #fff !important;
}

.navbar-expand .navbar-nav .nav-link {
  @media (max-width: 320.98px) {
    padding-left: 0px;
  }
}

.admin-body {
  min-height: calc(100vh - 140px);
}

.main-root-loading {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 9999;
}

.table {
  th.sortable {
    .fas {
      margin-left: 0.5rem !important;
    }
  }
}

.bootstrap-tagsinput .badge {
  background: $secondary;
  color: $gray-800;
  &:hover {
    a {
      color: $gray-800;
    }
  }
}

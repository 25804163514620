@import 'assets/scss/custom/_variables.scss';

.root {
  background: $gray-100;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;

  .progressBar {
    font-weight: bold;
    font-size: 16px;
    color: $white;

    span {
      padding-left: 10px;
    }
  }

  .body {
    padding: 5px 10px;

    .storyId {
      font-size: 10px;
      color: $gray-500;
      line-height: 19px;
      font-weight: normal;
    }

    .storyTitle {
      font-size: 13px;
      color: $black;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .footer {
    padding: 5px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .score {
      width: 22px !important;
      height: 22px !important;
      border-radius: 50%;
      margin-right: 7px;
      font-size: 12px;
      background: $primary;
      padding: 0;
    }

    .owner {
      font-size: 10px;
      color: $gray-800;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70px;
    }

    .epic {
      height: 22px;
      line-height: 22px;
      max-width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 8px;
      font-size: 10px;
      border: none;
    }
  }
  .summary {
    color: #32325d;
    font-weight: 600;
    font-size: 10px;
  }
  .taskDetails {
    padding: 5px 10px;
    color: #32325d;
    background: #dce5ef;
    position: relative;
    .grayOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #c4c4c4;
      opacity: 0.4;
    }
    .circleBackground {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      border-radius: 50%;
    }
    .completed {
      background: $white;
      color: $success;
      position: absolute;
      justify-content: center;
      font-size: 10px;
      border-radius: 47%;
      opacity: 0.9;
      i {
        opacity: 1;
      }
    }
    .userAvatar {
      width: 16px;
      height: 16px;
    }
    .noUser {
      background: #757575;
      color: $gray-900;
    }
  }
}

@import 'assets/scss/custom/_variables.scss';
.empty-avatar {
  background-color: $gray-300;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import 'assets/scss/custom/_variables.scss';

.plan {
  background-color: white;
  width: 350px;
  min-width: 350px;
  min-height: 650px;
  padding-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  position: relative;

  &.isMobile {
    width: calc(100% - 30px) !important;
    min-width: unset !important;
    max-width: 350px !important;
    min-height: unset !important;
  }

  .name {
    font-weight: bold;
    font-size: 28px;
    line-height: 25px;
    color: $green;
    text-align: center;
    margin-bottom: 35px;
  }

  .price {
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 0.03em;
    color: $gray-900;
    text-align: center;
  }

  .priceUser {
    display: none;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    color: white;
    text-align: center;
  }

  .description {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #666666;
    text-align: center;
    margin: 29px 45px 40px;

    &.isMobile {
      margin-bottom: 30px;
    }
  }

  .separator {
    height: 2px;
    width: calc(100% - 50px);
    background-color: #ebeced;
    margin: 0px auto;
  }

  .featureList {
    margin-top: 40px;
    margin-left: 45px;
    margin-bottom: 0px;
    padding: 0px;
    list-style: none;
    flex: 1;

    .featureText {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      color: #666666;
      margin-bottom: 22px;

      &:before {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-weight: 900;
        margin-right: 15px;
        color: $green;
      }
    }
  }

  .growth {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    color: white;
    display: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    position: relative;
    &::after,
    &::before {
      content: '';
      height: 1px;
      background-color: white;
      width: 37%;
      position: absolute;
      top: 13px;
      right: 0px;
    }
    &::before {
      left: 0px;
      right: unset;
    }
  }

  .outlineBtn {
    background: #f7fafc !important;
    box-shadow: 0px 0px 0px !important;
    border-radius: 4px;
    margin-bottom: 45px;
    margin-right: 40px;
    margin-left: 40px;
    border: 2px solid $green !important;
    color: $green !important;

    &.isMobile {
      margin-bottom: 30px;
    }
  }

  .ribbon {
    padding: 0px 10px;
    height: 30px;
    position: absolute;
    right: -9px;
    top: 20px;
    background: #fff;
    color: #222;
    display: none;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.342857px;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: 29.5px;
      right: 0.1px;
      border-top: 9px solid #222;
      border-right: 9px solid transparent;
    }

    &::after {
      content: '';
      height: 0;
      width: 0;
      left: -14.5px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-right: 15px solid #fff;
      position: absolute;
    }
  }

  &.yellow {
    $yellowColor: #ffb804;

    .name {
      color: $yellowColor;
    }

    .featureList {
      .featureText {
        &:before {
          color: $yellowColor;
        }
      }
    }

    .outlineBtn {
      border: 2px solid $yellowColor !important;
      color: $yellowColor !important;
    }
  }

  &.pro {
    background-color: $lyght-blue;
    width: 380px;
    min-width: 380px;
    min-height: 700px;

    .name,
    .description,
    .price,
    .featureText,
    .outlineBtn {
      color: white !important;
    }

    .description {
      margin-top: 10px;
    }

    .priceUser {
      display: block;
    }

    .featureList {
      margin-top: 60px;

      .featureText {
        &:before {
          color: white;
        }
      }
    }

    .growth {
      display: block;
    }

    .outlineBtn {
      border: 0px !important;
      background: linear-gradient(
        92deg,
        #fc5602 0.26%,
        #ffa800 131.46%
      ) !important;
      font-size: 18px;
      height: 47px;
    }

    .ribbon {
      display: flex;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  //z-index: 999;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
}
.quickStart {
  z-index: 9999999;
  //height: 300px;
  padding: 24px 80px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  &.hide {
    transform: translateY(100%);
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
  }
  .close {
    font-size: 14px;
    line-height: 30px;
    text-align: right;
    cursor: pointer;
  }
  .title {
    font-size: 28px;
    line-height: 38px;
    font-weight: bold;
    color: #4f4f4f;
  }
  .section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 12px 24px 16px;
    margin: 0 15px;
    width: 25%;
    height: initial;
    transition: all 0.2s;
    min-width: 310px;
    @media (max-width: 1440px) {
      min-width: unset;
    }
    i {
      font-size: 32px;
      @media (max-width: 1440px) {
        font-size: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 22px;
      }
    }
    &:hover {
      background: #ffffff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      transition: all 0.2s;
      cursor: pointer;
    }
    &.active {
      background: rgba(45, 156, 219, 0.1);
      border-radius: 4px;
    }
    &.completed {
      background: rgba(111, 207, 151, 0.1);
      border-radius: 4px;
    }
  }
  .sectionTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #4f4f4f;
    margin-left: 8px;
    @media (max-width: 1440px) {
      font-size: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
    }
  }
  .description {
    font-size: 14px;
    line-height: 30px;
    color: #4f4f4f;
    width: 100%;
  }
  .minimize {
    background: linear-gradient(141.98deg, #38cd92 8.89%, #39cec3 84.33%);
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.1),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
    padding: 12px 16px;
    position: absolute;
    top: -48px;
    left: calc(50% - 72px);
    cursor: pointer;
  }
  .rotate {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
  .success {
    color: #6fcf97;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
.tooltip {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: linear-gradient(
    141.98deg,
    #38cd92 8.89%,
    #39cec3 84.33%
  ) !important;
  box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.25);
  color: white;
  padding: 1rem;
  width: 240px;
  border-radius: 10px;
  &.startTimerInput {
    transform: translate(20px, 0px);
    .arrow {
      top: 45%;
      left: 97%;
    }
  }
  &.createClientInput {
    transform: translate(20px, 10px);
    .arrow {
      top: 41% !important;
      left: 97% !important;
    }
  }
  &.topBarIcon {
    transform: translate(0px, -10px);
    .arrow {
      left: 45%;
      top: -8px;
    }
  }
  &.sidebarIcon {
    transform: translate(-20px, 0px);
    .arrow {
      left: -8px;
      top: 42%;
    }
  }
  &.viewKanban {
    transform: translate(0px, -20px);
    .arrow {
      left: 47%;
      top: -7%;
    }
  }
  &.sidebarIconInner {
    transform: translate(-10px, 0px);
    .arrow {
      left: -8px;
      top: 42%;
    }
  }
  &.translateTooltip {
    transform: translate(-20px, 0px);
  }
  &.fullSidebarIcon {
    transform: translate(-10px, 0px);
    .arrow {
      left: -8px;
      top: 42%;
    }
  }
  &.addProjectInput {
    transform: translate(20px, -5px);
    .arrow {
      top: 48%;
      left: 97%;
    }
  }
  &.addBtn {
    transform: translate(10px, 0);
    .arrow {
      top: 42%;
      left: 97%;
    }
  }
  &.userEmailInput {
    transform: translate(20px, 10px);
    .arrow {
      top: 42%;
      left: 97%;
    }
  }
  &.inviteBtn {
    transform: translate(-20px);
    .arrow {
      top: 42%;
      left: -8px;
    }
  }
  &.createClientBtn {
    transform: translate(-20px);
    .arrow {
      left: -8px;
      top: 42%;
    }
  }
  &.createProjectBtn {
    transform: translate(-20px);
    .arrow {
      left: -8px;
      top: 42%;
    }
  }
  &.storyNavigationDropdown {
    transform: translate(10px);
    .arrow {
      left: 97%;
      top: 37%;
    }
  }
  &.storyNavigationBacklog {
    transform: translate(5px, -20px);
    .arrow {
      left: 45%;
      top: -8%;
    }
  }
  &.storyNavigationTeam {
    transform: translate(0px, -22px);
    .arrow {
      left: 81%;
      top: -8%;
    }
  }
  &.sectionInputTitle {
    transform: translate(-480px, -20px);
    .arrow {
      left: 47%;
      top: -8%;
    }
  }
  &.sectionLastStory {
    transform: translate(0px, -15px);
    .arrow {
      left: 47%;
      top: -8%;
    }
  }
  &.storyModalStoryName {
    transform: translate(20px, 50px);
    .arrow {
      left: 97%;
      top: 42%;
    }
  }

  &.inviteToTeamBtn {
    transform: translate(-20px);
    .arrow {
      left: -8px;
      top: 42%;
    }
  }
  &.addUserToProjectInput {
    transform: translate(20px);
    .arrow {
      top: 42%;
      left: 97%;
    }
  }
  &.inviteTeamBtn {
    transform: translate(10px);
    .arrow {
      left: 97%;
      top: 42%;
    }
  }
  &.projectsTable {
    transform: translate(0, 20px);
    .arrow {
      top: 90%;
    }
  }
  .arrow {
    width: 15px;
    height: 15px;
    background: linear-gradient(
      141.98deg,
      #38cd92 8.89%,
      #39cec3 84.33%
    ) !important;
    position: absolute;
    top: 9px;
    left: 45%;
    transform: rotate(45deg);
    /*&.left {
      top: 45%;
      left: 90%;
    }
    &.right {
      left: 10px;
      top: 45%;
    }*/
  }
  .tooltipTitle {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
  .dismiss {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #ffffff;
    cursor: pointer;
  }
  .tooltipContent {
    margin-top: 0.5rem;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

:global {
  .__floater__arrow {
    display: none;
  }
  .react-joyride__spotlight {
    border: 3px solid rgba(0, 147, 253, 0.01);
    box-shadow: 0px 0px 4px 2px rgba(0, 147, 253, 0.35);
    border-radius: 15px !important;
  }
  .hide-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

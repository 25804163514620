@import 'assets/scss/custom/_variables.scss';

.profileLoader {
  height: calc(100vh - 150px) !important;
}
.popoverClass {
  margin: 0.1rem 0;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.clientProfile {
  .name {
    font-weight: 600;
  }
  .options {
    min-width: 150px;
  }
  .textField {
    cursor: default !important;
    vertical-align: middle !important;
    @media (max-width: 768px) {
      max-width: 40vw;
    }
    span,
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: fit-content;
    }
  }
  .agreementsCardBody {
    overflow-x: hidden;
    .selectorPopover > .selectorPopoverWrapper > .popoverBody {
      min-width: 12rem;
    }
    :global {
      ._loading_overlay_wrapper {
        .react-bootstrap-table {
          table {
            table-layout: fixed;
            overflow-y: auto;

            thead,
            tbody,
            tr {
              width: 100%;
              @media (max-width: 480px) {
                // using vw here so that we can show the details to user
                width: 130vw;
              }
            }
          }
          .react-bs-table-no-data {
            white-space: normal;
            text-align: center;
          }
        }
      }
      .react-bootstrap-table-pagination {
        position: sticky;
        bottom: 0;
        margin-top: auto;
        background-color: $white;
      }
    }
  }
  .actions {
    width: 33.33%;

    i {
      cursor: pointer;
    }
  }
  .grey {
    color: $gray-400;
  }
  .addAgreement {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 320px;
  }
  .cards {
    max-height: 600px;
  }
}
.customIconWrapper {
  width: 88px;
  height: 88px;
  margin: 20px auto;
}

.timeSpentChart {
  min-height: 35rem;
  display: flex;
}

.recentTimeEntries {
  .cardBody {
    &.scroll {
      max-height: 37.5rem;
      overflow-y: auto;
    }
    .table {
      overflow: auto;
      table-layout: fixed;
      .row {
        @media (max-width: 480px) {
          // using vw here so that we can show the details to user
          width: 130vw;
        }
        .userColumn {
          .initiative {
            text-overflow: ellipsis;
          }
        }
        .storyColumn {
          max-width: 300px;
          white-space: normal;
        }
      }
    }
  }
}

.noTimeLogImg {
  max-width: 300px;
}

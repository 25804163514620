@import 'assets/scss/custom/_variables.scss';

.root {
  .card {
    background-color: $gray-100;
    margin-bottom: 0;
  }
}
.brandImage {
  width: 75%;
  min-width: 200px;
}

.createSprintModal {
  :global {
    .modal-title {
      font-size: 18px;
    }
  }

  .dateWrapper {
    position: relative;

    .icon {
      position: absolute;
      top: 28%;
      right: 3%;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .cancelButton {
    font-size: 14px;
  }

  .createButton {
    font-size: 14px;
  }
}

@import 'assets/scss/custom/_variables.scss';

.tabs {
  background-color: white;
  border: 1px solid $gray-200;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin: 0px auto 30px;
  width: calc(100% - 30px) !important;
  max-width: 350px !important;

  .tab {
    flex: 1;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    &.activeTab {
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
      background-color: $green;
      color: white;
      border-radius: 25px;

      &.green {
        background-color: $green;
      }
      &.blue {
        background-color: $lyght-blue;
      }
      &.yellow {
        background-color: #ffb804;
      }
    }
  }
}

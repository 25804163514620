@import 'assets/scss/custom/_variables.scss';

.root {
  :global {
    .custom-control-label {
      height: auto !important;
    }
  }
  .card {
    background-color: $gray-100;
    margin-bottom: 0;
  }
}

.brandImage {
  width: 50%;
  min-width: 200px;
}

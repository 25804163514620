@import 'assets/scss/custom/_variables.scss';

.section {
  &.dragging {
    height: 3.625rem !important;
  }
  :global {
    .card-body {
      padding: 0 15px !important;
    }
  }
}

.sprint {
  border: 2px solid #35b0f0;
  border-radius: 6px;
  &.dragging {
    height: 3.625rem !important;
  }
  :global {
    .card-body {
      padding: 0 15px !important;
    }
  }
}

.sprintHeader {
  background: #d7f1ff;

  .dateText {
    font-weight: 600;
    font-size: 14px;
    color: $gray-800;
    margin-right: 20px;
    @media (max-width: 480px) {
      margin: 0;
      font-size: 12px;
    }
  }

  .viewButton {
    font-size: 14px;
    color: #2d9cdb;
  }
}

.loading {
  position: absolute;
  z-index: 999;
  height: calc(100vh - 150px) !important;
}

.draggedOverCard {
  box-shadow: 0 0 1.5rem $primary !important;
}

.heading {
  background-color: $gray-100;
  padding: 0.635rem 0 0.635rem 5px;

  .columnHeading {
    text-transform: uppercase;
    font-size: $table-head-font-size;
    @media (max-width: 768px) {
      min-width: 190px;
    }
    @media (max-width: 480px) {
      min-width: 150px;
    }

    &.columnHeadingSmall {
      @media (max-width: 768px) {
        min-width: 150px;
      }
      @media (max-width: 480px) {
        min-width: 110px;
      }
    }
  }
}
.sectionSearch {
  @media (max-width: 468px) {
    flex-direction: column;
  }
  .searchWrapper {
    flex: 1;
    input {
      width: 50%;
      @media (max-width: 468px) {
        width: 100%;
      }
    }
  }
  .actionWrapper {
    flex: 1;
    justify-content: flex-end;
    @media (max-width: 468px) {
      margin-top: 1rem;
      margin-right: 0.5rem;
    }

    .createSprintButton {
      background: #9b51e0;
      color: white;
    }
  }
}
.lastColumn {
  width: 5.5rem;
  .commentCount {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 12px;
    }
    @media (max-width: 768px) {
    }
  }
  @media (max-width: 768px) {
    min-width: 60px !important;
  }
}
.titleColumn {
  @media (max-width: 991px) {
    width: calc(50% - 10.3rem);
  }
  @media (min-width: 992px) {
    width: calc(33.33% - 5.5rem);
  }
  @media (max-width: 768px) {
    min-width: 190px;
  }
  @media (max-width: 480px) {
    min-width: 150px;
  }
}
.pointer {
  cursor: pointer;
  width: fit-content;
}
.storyName {
  overflow-x: hidden;
  @media (max-width: 768px) {
    width: 150px;
  }
  span {
    width: 100%;
  }
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    &:hover {
      cursor: pointer;
      color: $lyght-blue;
    }
  }
}

.priority {
  &.pointer {
    cursor: pointer;
  }
}
.tooltip {
  max-width: unset;
}

.otherColumns {
  width: 15%;
  @media (max-width: 768px) {
    min-width: 140px;
  }
  @media (max-width: 480px) {
    min-width: 110px;
  }
}
.plusIcon {
  padding: 5px 11.425px;
  background: $gray-400;
  color: $white;
  font-size: 17px !important;
}
.toggleIcon {
  cursor: pointer;
  transition: all 0.3s;
  &.rotate {
    transform: rotate(90deg);
  }
}
.createBtn {
  background-color: $lyght-blue;
  &:disabled {
    color: $gray-400;
    background-color: transparent;
    border: 1px solid $gray-400;
  }
}
.maxWidthColumns {
  max-width: 150px;
  &.epicColumn {
    max-width: 300px;
    width: 16.6667%;
  }
}
.storyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.item {
  background-color: $white;
  //min-width: fit-content;
  &.story {
    border-left: 5px solid $lyght-blue;
    height: 46px;
  }
}
.dropdown {
  display: flex !important;
  :global {
    .btn-icon-only.btn-sm,
    .btn-group-sm > .btn-icon-only.btn {
      width: 2rem;
      height: 25px;
      line-height: 25px;
    }
  }
}
.loaderOverlay {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.toggle {
  padding: 0;
  border: unset;
  background: unset;
  box-shadow: unset;
  &:hover,
  &:focus {
    box-shadow: none !important;
    transform: unset !important;
    border: unset !important;
    background: unset !important;
  }
  &.disableButton {
    cursor: default !important;
  }
}

.dropdownTitle {
  font-size: 14px;
}
.scoreBadge {
  border-radius: 50% !important;
  justify-content: center;
  background: $lyght-blue;
  color: $white;
}
.activeButton {
  background: $lyght-blue !important;
  color: $white !important;
}
.importanceTextWrapper {
  width: 30px;
}
.importanceText {
  position: absolute;
  transform: rotate(-90deg);
  top: 50%;
  right: -20px;
}
.text {
  font-size: 12px;
  line-height: 16px;
  color: $gray-400;
}
.activeBadge {
  width: 23px;
  height: 23px;
  background: $lyght-blue;
  border-radius: 50%;
  color: $white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.epicDropdown {
  max-width: 90%;
  > button {
    max-width: 100%;
  }
}

.epicBadge {
  border-radius: 10rem !important;
  max-width: 100% !important;
  transition: all 0.3s;
  display: flex;
  &.disable {
    padding-right: 0.875em !important;
    cursor: default !important;
    span {
      cursor: default !important;
    }
  }
  .closeIcon {
    display: none;
  }
  &:hover {
    padding-right: 1.5rem;
    .closeIcon {
      transition: all 0.3s;
      right: 10px;
      position: absolute;
      display: block;
      cursor: pointer;
    }
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resultWrapper {
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.epicDropdownMenu {
  max-width: 200px;
  padding: 0.5rem 0.75rem;
}
.resultItem {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 6px;
  &.active {
    background: $lyght-blue;
    color: $white;
  }
  &:hover {
    background: $lyght-blue;
    color: $white;
  }
}
.closedHeader {
  border-radius: calc(0.375rem - 1px) !important;
}

.hoverHand {
  cursor: pointer !important;
}

.grabHand {
  cursor: grab;
}

.selector-dropdown {
  width: 12.5rem;
}
.duplicateModalBody {
  max-height: 600px;
  overflow: auto;
}

.sectionMargin {
  margin-top: 30px;
}

.sprintKanban {
  :global {
    .card-header {
      background-color: transparent;
      padding: 24px 0;
      border-bottom: 0;
    }
  }
}

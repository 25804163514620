.tableSearch {
  .searchInput {
    width: 160px;
  }
}
.loading {
  > div:first-child:not(:global(.react-bootstrap-table)) {
    height: calc(100% - 60px) !important;
  }
}

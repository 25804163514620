.cursor {
  cursor: pointer;
}
.badgeCount {
  position: absolute;
  right: 17px;
  top: 15px;
}
.desktopTabs {
  width: 6rem;
  &:last-of-type {
    margin-right: -1.5rem;
  }
}

@import 'assets/scss/custom/_variables.scss';

.card {
  height: 275px;

  .cardBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.text {
  font-weight: bold;
  color: $gray-800;
}

.manageBtn {
  background-color: $lyght-blue;
  color: white;
}

@import 'assets/scss/custom/_variables.scss';

.mainLoading {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 9999;
}

.billing {
  height: 100%;
  min-height: calc(100vh - 140px);

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: $gray-800;
    margin: 30px;

    &.leftAligned {
      text-align: left;
    }

    &.isMobile {
      text-align: center !important;
    }
  }

  .plans {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.alertMessageText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: $gray-700;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 26px;

  &.isMobile {
    ul {
      // padding: 0px 40px;
    }
  }

  ul {
    margin-bottom: 0px;
    padding: 0px;
    margin-top: 10px;
  }

  li {
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    margin-bottom: 5px;

    &:before {
      content: '';
      min-height: 10px;
      min-width: 10px;
      background-color: #525f7f;
      border-radius: 50%;
      margin-right: 5px;
      margin-top: 2px;
    }
  }
}

@import 'assets/scss/custom/_variables.scss';

.root {
  width: 100%;

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  &.fullscreen {
    padding-top: 20px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 1px;
    left: 1px;
    z-index: $zindex-modal;
    background: #f8f9fe;

    .content {
      height: calc(100vh - 12rem);
    }
  }

  .expand {
    color: #32325d;
    cursor: pointer;
  }
  .header {
    font-size: 14px;
    font-weight: normal;

    .subText {
      font-size: 14px;
      font-weight: 300;
      @media (max-width: 375.98px) {
        display: block;
      }
    }

    .searchBox {
      height: 30px;
      max-width: 250px;
      margin-left: 7px;
    }

    .status {
      font-size: 14px;
      font-weight: 300;
      margin-right: 20px;

      b {
        font-weight: 600;
        font-size: 18px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .content {
    flex: 1;
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    min-height: calc(100vh - 20rem);

    .column {
      margin-right: 20px;
      width: calc(100% / 4);
      display: flex;
      flex-direction: column;
      @media (max-width: 1023.98px) {
        width: 320px;
      }

      &:last-child {
        margin-right: 0px;
      }

      .list {
        width: 100%;
        @media (max-width: 1023.98px) {
          width: 320px;
        }
        padding: 10px 10px 0 10px;
        margin-top: 10px;
        background: $white;
        flex: 1 0 300px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        &.withFilter {
          margin-top: 6px;
        }
        > div {
          min-height: 100%;
        }
      }
    }
  }
}
.dropdown {
  margin: 0.125rem 0 0 0.125rem;
  .chevron {
    transform: rotate(90deg);
  }
  button {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    font-size: 17px;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
  .dropdownItem {
    font-size: 14px;
    line-height: 19px;
    color: $gray-700;
  }
}

.disablePointer {
  cursor: default !important;
}

.dropdown {
  margin: 0.125rem 0 0 0.125rem;
  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
}

.disablePointer {
  cursor: default !important;
}

@import 'assets/scss/custom/_variables.scss';

.dropdown {
  margin: 0.125rem 0 0 0.125rem;
  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
}

.default-cursor {
  cursor: default !important;
}

.text {
  font-size: $font-size-xs;
  line-height: 1rem;
  color: $gray-400;
}

.importance-text-wrapper {
  width: 1.875rem;
}

.importance-text {
  position: absolute;
  transform: rotate(-90deg);
  top: 50%;
  right: -20px;
}

.active-badge {
  width: 1.4rem;
  height: 1.3rem;
  background: $lyght-blue;
  border-radius: 50%;
  color: $white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.score-badge {
  background: $lyght-blue;
  color: $white;
}

.badge {
  border-radius: 50% !important;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: $font-size-xs;
}

.plus-icon {
  background: $gray-400;
  color: $white;
  font-size: 1rem !important;
}

.dropdown-title {
  font-size: $h5-font-size;
}

.active-button {
  background: $lyght-blue !important;
  color: $white !important;
}

.root {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  padding: 3px 10px;
  text-transform: capitalize;

  img {
    margin-left: 5px;
  }
}

@import 'assets/scss/custom/_variables.scss';

.hoverHand {
  &:hover {
    cursor: pointer;
  }
}

.inputDescription {
  resize: none;
}

.emptyDescription {
  height: 8rem !important;
}

.emptyContent {
  background-color: $gray-100;
}

.description {
  height: max-content;
  border-color: transparent;
  border-radius: 0.5rem;
  white-space: pre-line;
}

.projectOverview {
  h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeSpentChart {
  min-height: 35rem !important;
}

.calendar {
  :global {
    .rdtPicker {
      padding: 0.5rem;
      margin-top: -1.5rem !important;
      top: -17.5rem;
    }
  }
  &.end-date {
    :global {
      .rdtPicker {
        right: 0;
      }
    }
  }
}
.recentActivities {
  max-height: 600px;
  overflow: auto;
  padding: 1.5rem 3rem;
}

@import 'assets/scss/custom/_variables.scss';

.root {
  position: relative;
  .calendar {
    position: absolute;
    left: 0px;
    top: 46px;

    z-index: $zindex-dropdown;
    background-color: $dropdown-bg;
    border: $dropdown-border-width $dropdown-border-color solid;
    border-radius: $dropdown-border-radius;
    box-shadow: $dropdown-box-shadow;

    display: flex;

    @media (max-width: 1200.98px) {
      flex-direction: column-reverse;
    }

    .leftPanel {
      width: 150px;
      padding-top: 20px;
      padding-bottom: 10px;
      border-right: 1px solid $input-border-color;
      button {
        text-align: left;
        color: $input-color;
        font-weight: normal;
        padding: 7px 15px;
        margin-right: 0px;
        width: 100%;
        border-radius: 0px;
        &.active {
          background-color: $primary;
          color: $white;
        }
      }

      :global {
        .btn + .btn {
          margin-left: 0px;
        }
      }

      @media (max-width: 1200.98px) {
        width: 100%;
        border-right: none;
        border-top: 1px solid $input-border-color;
        padding: 20px;
        button {
          width: 50%;
        }
      }
    }

    :global {
      .CalendarDay {
        font-size: 0.875rem;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: $primary;
        border-color: $primary;
      }

      .CalendarDay__selected_span {
        background: #c9e6f9;
        border-color: #c9e6f9;
        color: $white;
        border-radius: 0px !important;
      }

      .CalendarDay__hovered_span,
      .CalendarDay__hovered_span:hover {
        background: #c9e6f9;
        border-color: #c9e6f9;
        color: #525f7f;
      }

      .DayPickerKeyboardShortcuts_show {
        display: none;
      }

      .CalendarDay__default {
        color: #525f7f;
        border: none;
      }

      .CalendarDay__default:hover {
        border-radius: 50%;
      }

      .CalendarDay__outside {
        color: #adb5bd;
      }

      .DayPicker_weekHeader_li small {
        font-size: 14px;
        color: #525f7f;
        font-weight: normal;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:hover {
        background: none !important;
        color: #fff !important;
        padding: 0px;
        position: relative;
        .startDate,
        .endDate {
          background-color: $primary !important;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
        }

        .rightBG {
          position: absolute;
          right: 0;
          top: 0;
          width: 50%;
          height: 100%;
          background: #c9e6f9;
          z-index: 1;
        }

        .leftBG {
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
          background: #c9e6f9;
          z-index: 1;
        }
      }

      .CalendarMonth_table {
        margin-top: 15px;
      }

      .navPrev {
        left: 35px;
        padding: 3px;
        position: absolute;
        top: 16px;
      }

      .navNext {
        right: 35px;
        padding: 3px;
        position: absolute;
        top: 16px;
      }

      .CalendarMonth_caption {
        font-size: 14px;
        strong {
          font-weight: normal !important;
        }
      }
    }
  }
}

@import 'assets/scss/custom/_variables.scss';

.card {
  .cardBody {
    .badge {
      align-self: flex-end;
    }
    .footer {
      p {
        margin-bottom: 0;
      }
      * {
        font-size: $font-size-sm;
        color: $text-muted;
      }
      :global {
        * :not(i) {
          font-weight: $font-weight-light;
        }
      }
    }
  }
}

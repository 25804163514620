@import 'assets/scss/custom/_variables.scss';

.clients {
  .clientName {
    vertical-align: middle !important;
    font-weight: 600;
    @media (max-width: 768px) {
      max-width: 20vw;
    }
    a {
      display: block;
      //width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }
  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .addClient {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 550px;
  }
  :global {
    .react-bootstrap-table.table-responsive {
      min-height: 150px;
      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
      table {
        table-layout: fixed;
      }
      .react-bs-table-no-data {
        white-space: normal;
        text-align: center;
      }
    }
  }
}

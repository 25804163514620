@import 'assets/scss/custom/_variables.scss';

.viewed-projects {
  .card-body {
    .noDataImg {
      max-height: 300px;
      &.loading {
        max-height: calc(130px - 2rem);
      }
    }
    &.scroll {
      .tableWrapper {
        max-height: 13.1rem !important;
        overflow-y: auto;
      }
    }
    .projectTitle {
      padding: $table-head-spacer-y $table-head-spacer-y * 2;
      text-transform: uppercase;
      border-bottom: $table-border-width solid $table-border-color;
      font-size: $table-head-font-size;
      font-weight: $table-head-font-weight;
      color: $table-head-color;
      background-color: $table-head-bg !important;
      border-color: $table-border-color;
    }
    .tableWrapper {
      table {
        table-layout: fixed;
        thead {
          display: none;
        }
        .project-name {
          a {
            font-weight: $font-weight-bold;
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: unset;
          }
        }
      }
    }
  }
}

.root {
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .rotate180 {
    transform: rotate(180deg);
  }
}
.navBar {
  position: sticky;
  top: 0;
  z-index: 9;
}
.notificationDropdown {
  max-width: 420px;
  @media (max-width: 480px) {
    width: calc(100vw - 10px) !important;
    min-width: unset;
  }
}
.dropdown-link {
  color: rgba(0, 0, 0, 0.9);

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
}

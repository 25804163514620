@import 'assets/scss/custom/_variables.scss';
.project-name-column {
  .project-name {
    font-weight: 600;
    height: 2rem;

    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }
}
@media (max-width: 1199px) {
  .project-name {
    width: 69vw;
  }
}
@media (min-width: 1200px) {
  .project-name {
    width: 10vw;
  }
}

@media (max-width: 576px) {
  .project-name {
    width: 40vw;
  }
}
.sort-caret {
  &:hover {
    cursor: pointer;
  }
}

.no-project-img {
  max-width: 250px;
}
.project-status {
  color: $gray-600;
}

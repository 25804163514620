@import 'assets/scss/custom/_variables.scss';
.selector {
  p {
    margin: 0;
  }

  :global {
    .rselect__menu {
      width: 15rem;
    }
  }
}

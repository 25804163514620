@import 'assets/scss/custom/_variables.scss';

.card {
  background-color: $gray-100;
  margin-bottom: 0;
}
.brandImage {
  width: 50%;
  min-width: 200px;
}
.placeholderElement {
  z-index: 100;
}

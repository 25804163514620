@import 'assets/scss/custom/_variables.scss';

.users {
  .userName {
    vertical-align: middle !important;
    font-weight: 600;
    @media (max-width: 768px) {
      max-width: 20vw;
    }
    a {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }
  .middle {
    vertical-align: middle;
  }
  .duration {
    max-width: 150px;
  }
  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .addUser {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 550px;
  }
  :global {
    .react-bootstrap-table.table-responsive {
      overflow: visible;
      min-height: 150px;
      @media (max-width: 480px) {
        overflow: auto;
      }

      .rselect__menu {
        z-index: 9;
      }
      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
      table {
        table-layout: fixed;
      }
      .react-bs-table-no-data {
        white-space: normal;
        text-align: center;
      }
    }
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detailColumns {
  width: 22%;
  @media (max-width: 768px) {
    width: unset;
    max-width: 20vw;
  }
  @media (max-width: 480px) {
    width: 200px;
  }
}
.statusColumns {
  width: 12%;
  @media (max-width: 768px) {
    width: 160px;
  }
}
.roleColumn {
  width: 32%;
  min-width: 250px;
  @media (max-width: 768px) {
    width: 420px;
  }
}
.applyHeight {
  height: 46px;
}
.projectOption {
  &:hover {
    background: $gray-100;
    cursor: pointer;
  }
}
.selectedOption {
  :global {
    .rselect__multi-value {
      position: relative;
      transition: all 0.3s;
    }
    .rselect__multi-value__remove {
      display: none;
      &:hover {
        background: unset;
      }
    }
  }
  &:hover {
    :global {
      .rselect__multi-value {
        padding-right: 20px;
      }
      .rselect__multi-value__remove {
        display: block;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }
}

@import 'assets/scss/custom/_variables.scss';

:global(.g-sidenav-pinned .sidenav.fixed-left + * * * *) .card {
  @media (min-width: 1200px) {
    width: calc(25% - 62.5px);
  }
}
.card {
  position: fixed;
  top: 10.9375rem;
  bottom: 3.75rem;
  margin-bottom: 0;
  width: 25%;
  &.storiesMultiSelect {
    top: 14.75rem;
  }
  &.cardCollapsed {
    width: 43px !important;
  }
  .placeHolder {
    width: 43px;
    display: block;
    position: absolute;
    height: 100%;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    transition: all 200ms ease-in-out;
    border-radius: calc(0.375rem - 1px);

    span {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      position: absolute;
      top: 44%;
      right: -28px;
      width: 104px;
    }

    // Animations
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
    }
  }

  .content-wrapper {
    height: 100%;
    transition: all 200ms ease-in-out;
    // Animations
    &-enter {
      opacity: 0;
      width: 0px;
    }
    &-enter-active {
      opacity: 1;
      width: 320px;
    }
    &-exit {
      opacity: 1;
      width: 320px;
    }
    &-exit-active {
      opacity: 0;
      width: 0px;
    }
  }

  .epic-header {
    top: 0;
    left: 0;
    background-color: $white !important;
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
    .epic-title {
      background-color: $gray-100;
      padding-left: 2.25rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      text-transform: uppercase;
      font-size: $table-head-font-size;
    }
  }

  .no-epic-img-container {
    display: flex;

    .no-epic-img {
      min-width: 200px;
      max-width: 300px;
    }
  }

  .card-body {
    overflow-y: auto !important;

    .edit-not-allowed {
      min-height: 2rem;
    }

    .epic-name {
      overflow: hidden !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
      color: $gray-700;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
      hyphens: auto;
      h5 {
        color: $gray-700;
      }
    }
  }

  .create-epic-btn {
    background-color: $lyght-blue;

    &:disabled {
      color: $gray-400;
      background-color: transparent;
      border: 1px solid $gray-400;
    }
  }

  .color-badge {
    font-size: 0.375rem;
    &.edit-allowed {
      cursor: pointer;
    }
  }

  .collapse-button {
    position: absolute !important;
    z-index: 100 !important;
    top: 44.5% !important;

    margin: 0 !important;

    &.close {
      right: -1rem !important;
    }

    &.open {
      right: -1.2rem !important;
    }

    i {
      transition: all 0.3s ease-in-out;
      &.open {
        transform: rotate(0deg);
      }

      &.close {
        transform: rotate(180deg);
      }
    }
  }

  :global {
    .bg-white:focus {
      background-color: white !important;
    }
  }
}

@import 'assets/scss/custom/_variables.scss';

.root {
  :global {
    .modal-header {
      border-bottom: 1px solid $gray-200;
      .close {
        padding: 15px;
      }
    }

    .modal-body {
      padding: 1.25rem;
    }
  }
}

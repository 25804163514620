@import 'assets/scss/custom/_variables.scss';

.documentTitle {
  font-weight: 800;
  font-size: 24px;
  color: $black;
}

.sectionTitle {
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  color: $black;
}

.bodyText {
  font-weight: 500;
  font-size: 14px;
  color: $black;
}

.card {
  min-height: 33rem;
  .noTimeLogImg {
    min-width: 200px;
    max-width: 300px;
  }
  .loaderOverlay {
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
  }
}

@import 'assets/scss/custom/_variables.scss';

.item {
  border-left: 2px dashed $border-color;
  position: relative;
  font-size: 13px;
  padding-left: 40px;
  padding-bottom: 10px;

  &:last-child {
    border-left: none;
  }

  .userAvatar {
    position: absolute;
    top: 0;
    left: -12.5px;
    &.userAvatarLarge {
      left: -20.5px;
    }
  }
  .activityUser {
    width: 25px;
    height: 25px;
    &.activityUserLarge {
      width: 41px;
      height: 41px;
    }
  }
  .time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-600;
    &.timeLarge {
      height: 52px;
    }

    .fromTo {
      line-height: 18px;
      margin-bottom: 15px;
      font-weight: 600;
    }
  }

  .title {
    h5 {
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 8px;
    }
  }

  .description {
    font-size: 13px;
    color: $gray-700;
    margin-bottom: 1rem;
  }
  .comment {
    background: $gray-100;
    padding: 1.5rem;
    border-radius: 6px;
    max-width: 400px;
  }
}
.pointer {
  cursor: pointer;
}

.disabled {
  max-width: 62px !important;
  &:hover {
    max-width: 62px !important;
  }
}
/*This will restrict the behaviour of sidebar remaining open after clicking close*/
:global {
  body.g-sidenav-hidden.g-sidenav-show {
    .sidenav:hover {
      max-width: 250px;
    }
  }
  body.g-sidenav-hidden {
    .sidenav:hover {
      max-width: 0px;
    }
  }
  .navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
    background: unset;
  }
}
